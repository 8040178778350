<template>
	<div class="login">
		<el-card>
			<div class="login-title">欢迎使用</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="账号" prop="username" >
					<el-input placeholder="请输入账户名" v-model="ruleForm.username"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input placeholder="请输入密码" v-model="ruleForm.password"></el-input>
				</el-form-item>
				
			</el-form>
			<el-button type="primary" @click="login">登录</el-button>
			<div class="login-foot">本项目仅供学习参考，禁止商业使用</div>
			<div class="login-foot-two">服务器维护中</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ruleForm: {
					username: '',
					password:''
				},
				rules:{
					username:[
						{ required: true, message: '请输入账户名', trigger: 'blur' },
						{ min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
					],
					password:[
						{ required: true, message: '请输入密码', trigger: 'blur' },
						{ min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
					],
				}
			}
		},
		methods:{
			login(){
			  // window.sessionStorage.setItem('token','12356789')
			  // //通过编程式导航跳转到后台主页
			  // this.$router.push('/home')
			
			  // return
			  this.$refs.ruleForm.validate(async (valid)=>{
			    if(!valid) return
			    const res = await this.$http({
			    	path: '/user/login',
			    	method: 'post',
			    	params: this.ruleForm
			    });
			    //console.log(res.data,123657)
			    if(res.data.code != '200') return this.$message.error(
			      res.data.message
			    )
			    this.$message({
			      message:"登录成功",
			      type:'success'
			    })
				//console.log(res.data.result,111)
				let ceshi = this.ffym(res.data.result,this.cl)
				let result = JSON.parse(ceshi)
				//return
				window.localStorage.setItem('token',result.token)
				window.localStorage.setItem('user',this.ruleForm.username)
				window.localStorage.setItem('userInfo',JSON.stringify(result.userInfo))
			   // window.sessionStorage.setItem('token',result.token)
			   // window.sessionStorage.setItem('user',this.ruleForm.username)
			   // window.sessionStorage.setItem('userInfo',JSON.stringify(result.userInfo))
			   //window.sessionStorage.setItem('token','monidenglu')
			    //通过编程式导航跳转到后台主页
			    this.$router.push('/home')
			
			  })
			}
		}
	}
</script>

<style scoped>
	.login {
		height: 100%;
		width: 100%;
		/* background: #CDDCF9; */
		background-color: #C9EDFF;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.login-title{
		margin: 20px auto;
		text-align: center;
		font-size: 30px;
		font-weight: bold;
		color: #3798FF;
	}
	.login-foot{
		margin: 30px 0 0 0 ;
		font-size: 12px;
		color: #BB381D;
		text-align: right;
	}
	.login-foot-two{
		margin-top: 10px;
		font-size: 12px;
		color: #BB381D;
		text-align: right;
	}
	.el-input{
		width: 280px;
	}
	.el-button{
		width: 100%;
	}
	.el-card{
		/* background-color: #C9EDFF; */
		/* background: #CDDCF9; */
	}
</style>
